.price-inputs {
  border: 1px solid black;

  .inputs-header {
    display: flex;
    button {
      margin-left: auto;
    }

    padding: 5px;
  }

  form {
    border-top: 1px solid black;
  }

  .inputs-list {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pattern-options {
    display: flex;
    flex-direction: column;
  }
  .price-input {
    display: flex;

    & * {
      margin: auto;
    }

    label {
      width: 100px;
    }

    input {
      margin-left: 10px;
      width: 50px;
    }
  }
}
