.island-overview {
  border: 1px solid black;
  border-top: 0px solid black;
  padding: 5px 0;

  .prophet-link-wrapper {
    padding: 5px 0;
    a {
      color: white;
    }
  }
}
